@import "./src/assets/styles/scss/_vars.scss";






























































.repo {
  transition: 0.5s ease;
  background: rgba(0,0,0,0.5);
  padding: 1em;
  width: 80%;
  cursor: default;
  color: white;

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  &:hover,
  &:focus {
    background: rgba(0,0,0,0.75);
    padding: 2em 1em;
  }

  &__title-bar {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  &__links {
    justify-content: flex-end;
    flex: 1 0 auto;

    @extend %flex;
  }

  &__link {
     @extend %flex;

    &:not(:first-child) {
      padding-left: 0.5em;
    }

    &--wip {
      color: $green-bright;
      font-size: 0.8rem;
      line-height: 1;
      font-weight: 600;
      align-self: flex-start;
      padding-top: 0.25em;
    }
  }

  &__description {
    padding: 0.5em;
    color: #ccc;
    font-weight: 300;
  }

  &--special {
    order: -1;
    border: 2px solid $blue-bright;

    .repo {
      &__title {
        color: $blue-bright;
        font-weight: 400;
        letter-spacing: -1px;
      }

      &__link .fa-icon {
        color: transparentize($blue-bright, 0.4);

        &:hover {
          color: $blue-bright;
        }
      }
    }

    .tag {
      background: transparentize($blue-bright, 0.5);
    }
  }
}
.tag {
  padding: 0.1em 0.75em;
  border-radius: 0.25em;
  background: transparentize(lightseagreen, 0.5);
  text-transform: uppercase;
  font-size: 0.7em;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.4em;
  }
}
