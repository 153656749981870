@import "./src/assets/styles/scss/_vars.scss";























































































































.col-no-gutters {
  padding: 0;
}
.col-flex {
  display: flex;
  flex-direction: column;

  &-align {
    &-start {
      align-items: flex-start;
    }

    &-center {
      align-items: center;
    }

    &-end {
      align-items: flex-end;
    }
  }

  &-justify {
    &-start {
      justify-content: flex-start;
    }

    &-center {
      justify-content: center;
    }

    &-end {
      justify-content: flex-end;
    }
  }

  &-grow {
    &-1 {
      flex: 1 1 auto;
    }

    &-2 {
      flex: 2 1 auto;
    }

    &-3 {
      flex: 3 1 auto;
    }

    &-4 {
      flex: 4 1 auto;
    }

    &-5 {
      flex: 5 1 auto;
    }
  }
}
