@import "./src/assets/styles/scss/_vars.scss";



































































































































































.column {
  &--avatar {
    position: fixed;

    @media screen and (max-width: 767px) {
      padding-top: 12vw;
      position: unset;
    }
  }
  &--repos {
    padding: 3em 0;
    margin-left: auto;
    overflow-x: auto;
    height: 100vh;

    @media screen and (max-width: 767px) {
      margin: unset;
      overflow: unset;
    }
  }
}
.avatar {
  @media screen and (max-width: 700px) {
    height: 8em;
    width: 8em;
  }
}
.intro-text {
  color: $blue-bright;
  padding-top: 1em;
  text-align: center;

  &__name {
    background: transparentize(black, 0.25);
    clip-path: polygon(
      5% 8%,
      100% 1%,
      92% 95%,
      7% 89%
    );
    font-family: "Dancing Script";
    font-size: 2rem;
    padding: 0.25em 1em 0.45em;
  }

  &__role {
    background: $green-bright;
    clip-path: polygon(
      0% 0%,
      100% 1%,
      97% 82%,
      2% 97%
    );
    color: #222;
    font-size: 0.8rem;
    font-weight: 700;
    margin-top: -1.25em;
    padding: 0.5em;
    text-transform: uppercase;
  }

  &__contributor {
    background: transparentize(black, 0.5);
    padding: 1em 1.5em;
    border-radius: 2em;
    font-size: 0.8rem;

    &:hover &__wrapper {
      height: 5em;
    }

    &:hover &__icon {
      height: 50px;
      width: 50px;
      &:hover {
        transition: unset;
        opacity: 1;
      }
    }

    &__text {
      color: #ccc;
      font-weight: 400;
    }

    &__count {
      background: transparentize(#ccc, 0.85);
      border-radius: 1em;
      padding: 0.2em 0.7em;
      color: skyblue;
      margin-left: 0.5em;
      display: inline-flex;
    }

    &__wrapper {
      transition: 0.5s ease all;
      margin: 0;
      height: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      transition: 0.5s ease all;
      height: 0px;
      width: 0px;
      border-radius: 50%;
      margin: 0.5em;
      opacity: 0.7;
    }
  }
}

.social-links {
  padding-top: 2em;

  .social-link {
    padding: 0 0.75em;
  }
}

.treehouse-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  .treehouse-badge {
    height: 7em;
    width: 7em;
    margin: 0.5em;

    &__icon {
      height: 100%;
    }

    &[data-tooltip] {
      position: relative;
      display: flex;
      justify-content: center;

      &::before {
        content: attr(data-tooltip);
        position: absolute;
        padding: 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 0.8rem;
        background: transparentize(#000, 0.25);
        color: #fff;
        font-weight: 600;
        text-shadow: 0px 0px 5px black;
        border-radius: 0.25em;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.wrapper {
  @media screen and (max-width: 767px) {
    overflow-y: auto;
  }
}
