@import "./src/assets/styles/scss/_vars.scss";





































.v-container {
  display: flex;
  flex-direction: column;

  &.full-width {
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 100%;
  }
  s &.full-height {
    margin: 0;
    padding: 0;
    max-height: 100%;
    height: 100%;
    flex: 1 0 auto;
  }
}
