@import './vars';

::selection {
  background: transparentize($blue-vivid, 0.25);
  color: white;
}

::-webkit-scrollbar {
  width: 0.75em;
  background: none;

  &-track {
    margin: 2px;
    border-radius: 1em;
    background-color: transparentize(black, 0.85);
  }

  &-thumb {
    border-radius: 0.5em;
    box-shadow: inset 0 0 2px transparentize(black, 0.5);
    background-color: darken($indigo, 2);
  }
}

.fa-icon {
  color: transparentize(#fff, 0.5);
  transition: 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    color: $peach;
  }
}

body {
  background: radial-gradient(
    circle at bottom,
    #0c574d15 0%,
    #0e7b7925 5%,
    #07788535 10%,
    #30173d55 50%,
    #24214355 65%,
    #090A0F 100%
  );
}

.avatar {
  transform: rotate(12deg);
  background-size: 100%;
  background-repeat: no-repeat;
  height: 15em;
  width: 15em;
  border-radius: 50%;
  transition: 1s ease;
  opacity: 0.2;

  &[lazy='loading'] {
    background: black;
  }
  &[lazy='loaded'] {
    opacity: 1;
    background-image: url('../../images/avatar.png');
  }
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.hide-overflow {
  overflow: hidden;
  position: fixed;
}

.clickable {
  cursor: pointer;
}
