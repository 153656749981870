$blue-dark: #02050F;
$blue-deep: #05395b;
$blue-vivid: #00436f;
$blue-bright: deepskyblue;
$green-bright: #00c180;
$teal: lightseagreen;
$peach: #ff7c3a;
$indigo: #1b203d;
$mint: #039170;

%flex {
  display: flex;
  flex-wrap: wrap;

  &--centered {
    justify-content: center;
    align-items: center;
    align-content: center;

    @extend %flex;
  }
}

%text-white {
  color: white;
}
